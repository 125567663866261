:root {
  --text-color: #000;
  --date-picker-label-bg-color: #fff;
  --date-picker-label-text-color: #000;
  --background-color: #fff;
  --background-color-hover: #dadada;
  --icon-color: #000;
  --date-picker-border-color: #000;
  --button-background-color: #4ca6f0;
  --button-text-color: #fff;
  --button-background-color-hover: #3a91d8;
  --delete-background-color: #fd4343;
  --date-cell-selected-color: #2a69ff;
  --date-cell-today-color: #0750fa;
  --date-cell-selected-background-color: #eaf1ff;
  --date-cell-selected-background-start-end-color: #2a69ff;
  --date-cell-selected-background-start-end-color-hover: #4c81fc;
  --date-cell-selected-background-color-hover: #bbcef5;
  --from-date-border-color: #f0f2f5;
  --from-date-border-color-hover: #d1cece;
  --from-date-background-color: #d1cece;
  --custom-range-error: #ec3e3e;
  --timezone-border-color-hover: #e4e4e4;
  --trash-icon-color: #fff;
  --box-shadow-color: #000;
  --selected-date-color: #fff;
  --cancel-button-border-color: #000;
  --cancel-button-bg-color-hover: #d1cece;
  --chevron-icon-bg-color-hover: #e4e7e7;
}

[data-theme='dark'] {
  --text-color: #f2f2f2;
  --background-color: #333333;
  --date-picker-label-bg-color: transparent;
  --date-picker-label-text-color: #9ca3af;
  --background-color-hover: #252525;
  --icon-color: #aaaaaa;
  --date-picker-border-color: #fff;
  --button-background-color: #091118;
  --button-text-color: #fff;
  --button-background-color-hover: #000000;
  --delete-background-color: #fd4343;
  --date-cell-selected-color: #05102b;
  --date-cell-today-color: #00aeff;
  --date-cell-selected-background-color: #ffffff;
  --date-cell-selected-background-start-end-color: #091118;
  --date-cell-selected-background-start-end-color-hover: #041335;
  --date-cell-selected-background-color-hover: #c9c9c9;
  --date-cell-selected-text-color-hover: #000;
  --from-date-border-color-hover: #d1cece;
  --from-date-background-color: #d1cece;
  --custom-range-error: #ec3e3e;
  --timezone-border-color-hover: #e4e4e4;
  --trash-icon-color: #fff;
  --box-shadow-color: #fff;
  --selected-date-color: #fff;
  --cancel-button-border-color: #fff;
  --cancel-button-bg-color-hover: #131313;
  --chevron-icon-bg-color-hover: #111111;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
