.rating-options {
  & > div {
    &:last-child {
      & > div {
        & > div {
          @apply bg-gray-800 hover:bg-gray-700;
        }
      }
    }
  }
}
