* {
  @apply m-0 p-0 box-border antialiased;
}

html {
  @apply bg-white;
}

body {
  @apply h-full w-screen font-sans overflow-x-hidden;
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark textarea:-webkit-autofill,
.dark textarea:-webkit-autofill:hover,
.dark textarea:-webkit-autofill:focus,
.dark select:-webkit-autofill,
.dark select:-webkit-autofill:hover,
.dark select:-webkit-autofill:focus {
  -webkit-text-fill-color: #e5e7eb;
  -webkit-box-shadow: 0 0 0px 40rem #181a1b inset;
  transition: background-color 5000s ease-in-out 0s;
}
