/* The switch - the box around the slider */
#theme-toggle-button {
  font-size: 16px;
  width: 65px;
  display: flex;
  cursor: pointer;
}

/* Hide default HTML checkbox */
#toggle {
  opacity: 0;
  width: 0;
  height: 0;
}

#container,
#patches,
#stars,
#button,
#sun,
#moon,
#cloud {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.25s;
}

/* night sky background */
#toggle:checked + svg #container {
  fill: #2b4360;
}

/* move button to right when checked */
#toggle:checked + svg #button {
  transform: translate(28px, 2.333px);
}

/* show/hide sun and moon based on checkbox state */
#sun {
  opacity: 1;
}

#toggle:checked + svg #sun {
  opacity: 0;
}

#moon {
  opacity: 0;
}

#toggle:checked + svg #moon {
  opacity: 1;
}

/* show or hide background items on checkbox state */
#cloud {
  opacity: 1;
}

#toggle:checked + svg #cloud {
  opacity: 0;
}

#stars {
  opacity: 0;
}

#toggle:checked + svg #stars {
  opacity: 1;
}

.dark-mode-switch {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
