.diagnosis {
  tr {
    @apply border;
  }

  td,
  th {
    @apply px-4 py-2 border-x;
  }
}

.diagnosis-light {
  tr:nth-child(2) {
    @apply bg-gray-50;
  }
}
