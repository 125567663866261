.date-picker-label-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--date-picker-label-text-color);
  background-color: var(--date-picker-label-bg-color);
  color: var(--date-picker-label-text-color);
  border-radius: 4px;
  padding: 6px;
  outline: 1px;
  transition: all 300ms ease;

  svg {
    color: var(--icon-color);
  }

  &:hover {
    cursor: pointer;
    background: var(--background-color-hover);
  }
}

.date-picker-label {
  width: 100%;
  font-weight: 600;
  border-radius: 8px;
  color: var(--date-picker-label-text-color);
  outline: none;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
}

.date-picker {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--date-picker-border-color);
  border-radius: 12px;
  margin-top: 4px;
  padding: 4px 10px 4px 10px;
  background-color: var(--background-color);
  color: var(--text-color);
  z-index: 50;

  &-dropdown {
    &-menu {
      width: 150px;
      max-height: 250px;
    }

    &-item {
      padding: 8px 0;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color);
      cursor: pointer;
    }
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    height: 100%;

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      border-radius: 12px;
      border-color: transparent;
      background: var(--button-background-color);
      color: var(--button-text-color);

      padding: 0px 18px 0px 18px;
      margin-bottom: 24px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 2px;
        transition: all 300ms ease;
      }
    }

    &-ranges {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;
      margin-top: 24px;
      margin-bottom: 24px;

      &-add-button {
        display: flex;
        align-items: center;
        justify-content: start !important;
      }

      &-trash-icon {
        position: relative;
        z-index: 1;
        color: var(--trash-icon-color);

        &:hover {
          color: var(--delete-background-color);
        }
      }

      &-selected {
        background: var(--button-background-color-hover) !important ;
        box-shadow: 0 0 0 1px #fff;
      }

      button {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 4px 10px 4px 10px;
        background: var(--button-background-color);
        color: var(--button-text-color);
        border-radius: 12px;
        margin: 0;
        transition: all 200ms ease;

        &:hover {
          cursor: pointer;
          background: var(--button-background-color-hover);
        }

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
}

.from-date {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 40px);
  row-gap: 8px;
  color: var(--text-color);

  &-text {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    margin-left: 16px;
    margin-bottom: 10px;

    b {
      margin-right: 8px;
    }
  }
}

.from-date-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.from-date-label {
  color: var(--text-color);
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
}

.from-date-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  transition: all 300ms ease;

  &:hover {
    cursor: pointer;
    background: var(--date-cell-selected-background-color-hover);
    border-radius: 999px;
    color: var(--date-cell-selected-text-color-hover);
  }

  &-today {
    color: var(--date-cell-today-color);
  }

  &-opacity {
    opacity: 0.5;
  }
}

.from-date-cell-selected {
  color: var(--date-cell-selected-background-start-end-color);
  background: var(--date-cell-selected-background-color);
  transition: all 300ms ease;

  &:hover {
    background: var(--date-cell-selected-background-color-hover);
    color: var(--date-cell-selected-text-color-hover);
    border-radius: 0;
  }

  &-end-of-row {
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;

    &-hover {
      &:hover {
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
      }
    }
  }

  &-start-of-row {
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;

    &-hover {
      &:hover {
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;
      }
    }
  }

  &-start {
    color: var(--selected-date-color);
    border-radius: 999px;
    background: var(--date-cell-selected-background-color);

    &-range {
      border-radius: 0;
      border-top-left-radius: 999px;
      border-bottom-left-radius: 999px;
    }

    position: relative;
    z-index: 1;

    > div {
      background: var(--date-cell-selected-background-start-end-color);
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      transition: all 200ms ease;

      &:hover {
        background: var(--date-cell-selected-background-start-end-color-hover);
        color: var(--text-color);
        box-shadow: 0px 0px 0px 1px var(--box-shadow-color);
      }
    }

    &:hover {
      border-radius: 999px;
    }
  }

  &-end {
    color: var(--selected-date-color);
    border-radius: 999px;
    background: var(--date-cell-selected-background-color);

    &-range {
      border-radius: 0;
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
    }

    position: relative;
    z-index: 1;

    > div {
      background: var(--date-cell-selected-background-start-end-color);
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 999px;
      transition: all 200ms ease;

      &:hover {
        color: var(--text-color);
        background: var(--date-cell-selected-background-start-end-color-hover);
        box-shadow: 0px 0px 0px 1px var(--box-shadow-color);
      }
    }

    &:hover {
      color: var(--date-cell-selected-text-color-hover);
      border-radius: 999px;
    }
  }
}

.to-date {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 40px);
  row-gap: 8px;

  &-text {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    margin-left: 18px;
    margin-bottom: 10px;

    b {
      margin-right: 8px;
    }
  }
}

.to-date-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.calendar-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.ranges {
  display: flex;
  flex-direction: column;
}

.dates-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 14px;

  & > * + * {
    margin-left: 12px;
  }
}

.weekdays {
  width: 100%;
  align-items: center;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(7, 40px);
  color: var(--text-color);
  margin-bottom: 8px;

  p {
    margin: 0;
  }
}

.from-date-month-year-picker {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;

  & > * + * {
    margin-left: 8px;
  }

  &-icons {
    width: 14px;
    height: 14px;

    &-container {
      display: flex;
      align-items: center;
      border: 2px solid var(--from-date-border-color);
      border-radius: 6px;
      padding: 6px;
      transition: all;
      transition-duration: 300ms;

      &:hover {
        background: var(--chevron-icon-bg-color-hover);
        border: 2px solid var(--from-date-border-color-hover);
        cursor: pointer;
      }
    }
  }

  &-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
  }

  button {
    border: none;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Montserrat', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    transition: all 300ms ease;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

.custom-range-add {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  & > * + * {
    margin-left: 14px;
  }

  input {
    border-radius: 12px;
    border: 1px solid;
    padding: 8px 12px 8px 12px;
    color: var(--text-color);
    background: var(--background-color);
  }

  &-submit-button {
    background: var(--button-background-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 12px;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    transition: all 300ms ease;
    padding: 10px 24px 10px 24px;

    &:hover {
      cursor: pointer;
      background: var(--button-background-color-hover);
    }
  }

  &-cancel-button {
    background: transparent;
    color: var(--text-color);
    border: 1px solid var(--cancel-button-border-color);
    border-radius: 12px;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    transition: all 300ms ease;
    padding: 10px 24px 10px 24px;

    &:hover {
      cursor: pointer;
      background: var(--cancel-button-bg-color-hover);
    }
  }
}

.custom-range-label {
  display: flex;
  flex-direction: column;
}

.custom-range-error {
  position: absolute;
  z-index: 1;
  font-size: 14px;
  margin-top: 36px;
  color: rgb(236, 62, 62);
}

.timezone {
  margin-top: 10px;
  display: flex;
  align-items: center;

  &-utc {
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: 8px;
    }
  }

  &-options-select {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--date-picker-border-color);
    margin-left: 10px;
    border-radius: 10px;
    padding: 8px 4px;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      border-color: #e4e4e4;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    option {
      padding: 14px;
    }
  }
}

/* The switch - the box around the slider */
#theme-toggle-button {
  font-size: 14px;
  width: 65px;
  display: flex;
  cursor: pointer;
}

/* Hide default HTML checkbox */
#toggle {
  opacity: 0;
  width: 0;
  height: 0;
}

#container,
#patches,
#stars,
#button,
#sun,
#moon,
#cloud {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.25s;
}

/* night sky background */
#toggle:checked + svg #container {
  fill: #2b4360;
}

/* move button to right when checked */
#toggle:checked + svg #button {
  transform: translate(28px, 2.333px);
}

/* show/hide sun and moon based on checkbox state */
#sun {
  opacity: 1;
}

#toggle:checked + svg #sun {
  opacity: 0;
}

#moon {
  opacity: 0;
}

#toggle:checked + svg #moon {
  opacity: 1;
}

/* show or hide background items on checkbox state */
#cloud {
  opacity: 1;
}

#toggle:checked + svg #cloud {
  opacity: 0;
}

#stars {
  opacity: 0;
}

#toggle:checked + svg #stars {
  opacity: 1;
}

.dark-mode-switch {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
