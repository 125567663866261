.search-input:focus {
  background-color: transparent !important;
  color: #e5e7eb !important;
}

.dark .active-tab {
  background: #181a1b !important;
  border: 1px solid rgba(107, 114, 128, 1) !important;
  color: #e5e7eb !important;
}

.dark .table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #e5e7eb !important;
  background-color: #181a1b !important;
}
