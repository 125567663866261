.dark .cancelButton {
  background: transparent !important;
  color: rgb(229, 231, 235) !important;
}

.dark .applyButton {
  background: #0c4a6e !important;
  border-color: #075985;
}

.dark .rangecontainer {
  & > div {
    background: transparent !important;
    border-color: #6b7280 !important;
  }
}

.dark .daterangepicker {
  background: #181a1b !important;
}

.dark .dateTimeLabel {
  color: #d1d5db !important;
}

.dark .fromDateHourContainer {
  border-color: #6b7280 !important;
}

.dark .inputDate {
  color: #d1d5db !important;
}

.dark .timeSelectContainer {
  color: #d1d5db !important;
}
