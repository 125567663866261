.pagination-container-custom {
  @apply text-right pt-4;
}

.pagination-container-custom .pagination-custom {
  @apply flex items-center justify-center mb-4;
}

.pagination-custom .pagination-container-custom span {
  @apply text-sm;
}

.pagination-container-custom .pagination-custom .pagination-controls button {
  @apply bg-transparent border-none cursor-pointer outline-none pt-1.5;
}

.pagination-container-custom .pagination-custom .pagination-controls button i {
  @apply text-lg text-pagination-text;
}

.pagination-container-custom .custom-select__control {
  @apply w-16;
}

.page-item-custom.active .page-link-cutsom {
  @apply text-white bg-page-link-active;
}

.page-link-cutsom {
  @apply rounded-md border border-white;
}

.page-link-cutsom > i {
  @apply text-sm;
}

.page-link-cutsom:hover {
  @apply bg-white border-page-link-hover;
}

.page-item-custom.page-number {
  @apply hidden;
}
