.badge-c {
  @apply px-3 py-1 flex items-center rounded-lg text-xs border-1px transition-all;

  &-success {
    @apply border-badge-success text-badge-success hover:border-green-300 hover:text-green-300;

    &-active {
      @apply bg-badge-success text-badge font-medium;
    }
  }

  &-danger {
    @apply border-badge-danger text-badge-danger hover:border-red-300 hover:text-red-300;

    &-active {
      @apply bg-badge-danger text-badge font-medium;
    }
  }

  &-info {
    @apply dark:border-badge-info dark:text-badge-info;

    &-active {
      @apply dark:bg-badge-info bg-gray-200 dark:text-badge font-medium;
    }
  }
}
